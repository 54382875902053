<template>
    <div class="card d-block w-100 border-0 shadow-xss rounded-lg overflow-hidden p-4">
        <div class="row justify-content-center">
            <div class="col-12">
                <div class="card d-block w-100 border-0 shadow-xss rounded-lg overflow-hidden mb-4">
                    <ul id="pills-tab" role="tablist" class="nav nav-tabs xs-p-4 d-flex align-items-center justify-content-around product-info-tab border-bottom-0">
                        <li class="list-inline-item" :class="{'active' : $route.name == 'TOEFLAdmin'}">
                            <router-link :to="{name: 'TOEFLAdmin'}" class="fw-700 pb-sm-4 pt-sm-4 xs-mb-2 font-xssss text-grey-500 ls-3 d-inline-block text-uppercase" :class="{'active' : $route.name == 'TOEFLAdmin'}">Test Programs</router-link>
                        </li>
                        <li class="list-inline-item" :class="{'active' : $route.name == 'TOEFLSettingAdmin'}">
                            <router-link :to="{name: 'TOEFLSettingAdmin'}" class="fw-700 pb-sm-4 pt-sm-4 xs-mb-2 font-xssss text-grey-500 ls-3 d-inline-block text-uppercase" :class="{'active' : $route.name == 'TOEFLSettingAdmin'}">Setting</router-link>
                        </li>
                    </ul>
                </div>
                <div class="tab-pane fade show active" v-if="$route.name == 'TOEFLAdmin'">
                    <div class="card d-block w-100 border-0 shadow-xss rounded-lg overflow-hidden p-4">
                        <div class="row">
                            <div class="col-12">
                                <div class="row" v-if="isLoad">
                                    <div class="col-12 text-center">
                                        <div class="my-3">
                                            <div class="spinner-grow text-warning mx-3" role="status">
                                                <span class="sr-only">Loading...</span>
                                            </div>
                                            <div class="spinner-grow text-warning mx-3" role="status">
                                                <span class="sr-only">Loading...</span>
                                            </div>
                                            <div class="spinner-grow text-warning mx-3" role="status">
                                                <span class="sr-only">Loading...</span>
                                            </div>
                                            <div class="spinner-grow text-warning mx-3" role="status">
                                                <span class="sr-only">Loading...</span>
                                            </div>
                                            <div class="spinner-grow text-warning mx-3" role="status">
                                                <span class="sr-only">Loading...</span>
                                            </div>
                                            <div class="spinner-grow text-warning mx-3" role="status">
                                                <span class="sr-only">Loading...</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row" v-else>
                                    <div class="col-12">
                                        <h1 class="mt-4 font-weight-bolder"><i class="fas fa-file mr-3"></i>Test Programs</h1><hr>
                                        <div class="row justify-content-center d-flex" v-if="toefl.length">
                                            <div  class="col-lg-12">
                                                <input type="text" class="form-control" placeholder="Search..." v-model="tableFilter">
                                            </div>
                                            <div class="col-xl-12 col-lg-12 text-center py-3">
                                                <div class="table-responsive mw-100 px-1">
                                                    <datatable :class="'table table-hover table-bordered'" :columns="columns" :filter="tableFilter" :data="toefl" :page="1" :perPage="10">
                                                        <template name="default" slot-scope="{ row, index }">
                                                            <tr v-if="row">
                                                                <td class="text-center">{{ index+1 }}</td>
                                                                <td>{{ row.fullname }}</td>
                                                                <td>{{ row.id_no }}</td>
                                                                <td>{{ row.partner_id.title }}</td>
                                                                <td>{{ row.category_test }}</td>
                                                                <td class="text-center">{{ row.test_date | formatDate }}</td>
                                                                <td class="text-center">{{ row.times_taken }}</td>
                                                                <td>{{ row.user_name }}</td>
                                                                <td>{{ row.user_email }}</td>
                                                                <td>
                                                                    <span :class="{'badge-warning':row.status == 1,'badge-info':row.status==2,'badge-success':row.status==3,'badge-danger':(row.status==4||row.status==5||row.status==6)}" class="badge p-1 text-white">{{ formatStatus(row.status) }}</span>
                                                                </td>
                                                                <td>
                                                                    <a @click="showModal(row, 'detail-modal')" href="javascript:void(0)" title="Detail" class="text-info mr-2"><i class="fas fa-info-circle"></i></a>
                                                                    <a v-if="row.status == 1" @click="showModal(row, 'confirm-modal')" href="javascript:void(0)" title="Confirmation" class="text-success mr-2"><i class="fas fa-check"></i></a>
                                                                    <a @click="exportPdfTestPrograms(row)" href="javascript:void(0)" title="ExportPdf" class="text-info"><i class="fas fa-download"></i></a>
                                                                </td>
                                                            </tr>
                                                            <tr v-else>
                                                                <td colspan="9">Nothing to see here</td>
                                                            </tr>
                                                        </template>
                                                        <template name="no-result">
                                                            Nothing to see here
                                                        </template>
                                                    </datatable>
                                                </div>
                                                <datatable-pager v-model="page" type="abbreviated"></datatable-pager>
                                            </div>
                                        </div>
                                        <div class="row" v-else>
                                            <div class="col-12 text-center py-3"> 
                                                <img :src="'/images/empty.png'" alt="" width="300">
                                                <h1 class="text-muted">Data don't exist</h1>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tab-pane fade show active" v-if="$route.name == 'TOEFLSettingAdmin'">
                    <div class="card d-block w-100 border-0 shadow-xss rounded-lg overflow-hidden p-4">
                        <Setting></Setting>
                    </div>
                </div>
            </div>
        </div>
        <modal name="detail-modal"
            :width="'80%'"
            :height="'auto'"
            :scrollable="true"
            @before-close="beforeClose">
            <div class="container-fluid">
                <div class="row mb-0 mt-2 py-2">
                    <div class="col-12 px-4 py-0">
                        <a @click="closeModal('detail-modal')" href="javascript:void(0)" class="float-right"><i class="fas fa-times text-danger"></i></a>
                    </div>
                </div>
                <div class="row align-item-center justify-content-center pb-3">
                    <div class="col-12">
                        <Detail @update-data="closeModal('detail-modal')" :data="current"></Detail>
                    </div>
                </div>
            </div>
        </modal>
        <modal name="confirm-modal"
            :width="'80%'"
            :height="'auto'"
            :scrollable="true"
            @before-close="beforeClose">
            <div class="container-fluid">
                <div class="row mb-0 mt-2 py-2">
                    <div class="col-12 px-4 py-0">
                        <a @click="closeModal('confirm-modal')" href="javascript:void(0)" class="float-right"><i class="fas fa-times text-danger"></i></a>
                    </div>
                </div>
                <div class="row align-item-center justify-content-center pb-3">
                    <div class="col-12">
                        <Confirm @update-data="closeModal('confirm-modal')" :data="current"></Confirm>
                    </div>
                </div>
            </div>
        </modal>
    </div>
</template>

<script>
import axios from 'axios'
import moment from 'moment'
import Detail from './Detail.vue'
import Confirm from './Confirm.vue'
import Setting from './Setting.vue'
export default {
    name: "Privillage",
    components: {
        Confirm,
        Detail,
        Setting
    },
    data(){
        return {
            isLoad: true,
            toefl: [],
            tableFilter: '',
            current: null,
            columns: [
                {label: 'No', field: '', headerClass: 'border-0 bg-current text-white p-3', class: 'product-p text-center', filterable: false, sortable: false},
                {label: 'Full Name', field: 'fullname', headerClass: 'border-0 bg-current text-white p-3', class: 'product-p text-center'},
                {label: 'ID Number', field: 'id_no', headerClass: 'border-0 bg-current text-white p-3', class: 'product-p text-center'},
                {label: 'Information Source', field: 'partner_id', headerClass: 'border-0 bg-current text-white p-3', class: 'product-p text-center'},
                {label: 'Test Program', field: 'category_test', headerClass: 'border-0 bg-current text-white p-3', class: 'product-p text-center'},
                {label: 'Test Date', field: 'test_date', headerClass: 'border-0 bg-current text-white p-3', class: 'product-p text-center'},
                {label: 'Times Taken', field: 'times_taken', headerClass: 'border-0 bg-current text-white p-3', class: 'product-p text-center'},
                {label: 'Sender Name', field: 'user_name', headerClass: 'border-0 bg-current text-white p-3', class: 'product-p text-center'},                          
                {label: 'Sender Email', field: 'user_email', headerClass: 'border-0 bg-current text-white p-3', class: 'product-p text-center'},
                {label: 'Status', field: '', headerClass: 'border-0 bg-current text-white p-3', class: 'product-p text-center', filterable: false, sortable: false},                          
                {label: 'Action', field: '', headerClass: 'border-0 bg-current text-white p-3', class: 'product-p text-center', filterable: false, sortable: false},                          
            ],
            page: 1
        }
    },

    created(){
        this.getTOEFL()
    },
    methods:{
        showModal(item, name){
            if(item) {
                this.current = item
                this.$modal.show(name)
            }
        },
        closeModal(name){
            this.$modal.hide(name)
        },
        beforeClose() {
            this.current = null
            this.getTOEFL()
        },
        formatStatus(str = '') {
            if(str == 1) {
                return 'Waiting Approval'
            } else if(str == 2) {
                return 'Waiting For Payment'
            } else if(str == 3) {
                return 'Active (Paid)'
            } else if(str == 4) {
                return 'Expired'
            } else if(str == 5) {
                return 'Refund'
            } else if(str == 6) {
                return 'Canceled'
            }
        },
        async getTOEFL() {
            await axios.get(`${process.env.VUE_APP_URL_API}/admin/setting/toefl`, {
                headers: {
                    Authorization: `Bearer ${localStorage.token}`
                }
            }).then(res => {
                this.toefl = res.data.data
                this.isLoad = false
            })
        },
        formatDate(date) {
            return moment(date).format('MMMM DD, YYYY');
        },
        async exportPdfTestPrograms(data){
            this.filename = data['fullname'] + '_' + this.formatDate(data['created_at']) + '.pdf'; 
            
            axios({
                url: `${process.env.VUE_APP_URL_API}/admin/setting/toefl/export-PDF?data=${data['id']}`,
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${localStorage.token}`
                },
                responseType: 'blob',
            }).then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', this.filename);
                document.body.appendChild(link);
                link.click();
            });
        },
    }

}
</script>
