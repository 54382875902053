<template>
    <div class="row">
        <div class="col-12">
            <div class="row" v-if="isLoad">
                <div class="col-12 text-center">
                    <div class="my-3">
                        <div class="spinner-grow text-warning mx-3" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <div class="spinner-grow text-warning mx-3" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <div class="spinner-grow text-warning mx-3" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <div class="spinner-grow text-warning mx-3" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <div class="spinner-grow text-warning mx-3" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <div class="spinner-grow text-warning mx-3" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row" v-else>
                <div class="col-12">
                    <h1 class="mt-4 font-weight-bolder"><i class="fas fa-file mr-3"></i>Setting Price</h1><hr>
                        <div class="mb-7 row">
                            <div class="col fv-row">
                                
                            </div>
                        </div>
                    <form @submit.prevent="onSubmit()" class="row justify-content-center d-flex">
                        <div class="form-group col-6">
                            <label for="" class="text-current fw-600">Information Source<br><small>Sumber Informasi</small></label>
                            <select v-model="setting.partner_id" required class="form-control" @change="getPrice">
                                <option value="" selected disabled>-- Select Information Source --</option>
                                <option :value="item.id" v-for="(item, index) in partner" :key="index">
                                    <span>
                                    {{ item.title }}
                                    </span>
                                </option>
                            </select>
                        </div>
                        <div class="form-group col-6">
                            <label for="" class="text-current fw-600">Test Program<br><small>Jenis Tes</small></label>
                            <select v-model="setting.category_test" class="form-control" @change="getPrice" required>
                                <option value="" selected disabled>-- Select your Test Program --</option>
                                <option value="TOEFL ITP">TOEFL ITP</option>
                                <option value="TOEFL iBT">TOEFL iBT</option>
                                <option value="TOEIC">TOEIC</option>
                                <option value="IELTS">IELTS</option>
                            </select>
                        </div>
                        <div class="form-group col-12">
                            <label for="">Amount <span class="font-xssss text-warning">(Per Times Taken Test)</span></label>
                            <input  type="number" class="form-control" placeholder="Insert your Amount" v-model="setting.amount" required>
                        </div>
                        <div class="form-group col-lg-12 text-right mt-2">
                            <button class="btn btn-current" type="submit">Submit</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios'
export default {
    data() {
        return {
            isLoad: true,
            partner: [],
            amount1: [],
            setting: {
                id: '',
                amount: '',
                partner_id: '',
                category_test: ''
            }
        }
    },
    created(){
        //this.getSetting()
        this.getPartner()
    },
    methods: {
        // async getSetting() {
        //     await axios.get(`${process.env.VUE_APP_URL_API}/admin/setting/setting-toefl`, {
        //         headers: {
        //             Authorization: `Bearer ${localStorage.token}`
        //         }
        //     }).then(res => {
        //         if(res.data.success) {
        //             this.setting = res.data.data
        //         }
        //     }).finally(() => this.isLoad = false)
        // },
        async getPartner() {
            await axios.get(`${process.env.VUE_APP_URL_API}/core/toefl/partner`, {
                headers: {
                    Authorization: `Bearer ${localStorage.token}`
                }
            }).then(res => {
                this.partner = res.data
            }).finally(() => this.isLoad = false)
        },
        async getPrice(){
            this.setting.id = ''
            this.setting.amount = ''
            let data = this.setting
            if (data.category_test != '' && data.partner_id != '') {
                await axios.get(`${process.env.VUE_APP_URL_API}/admin/setting/setting-toefl?`, {
                    params: {
                        partner_id: data.partner_id,
                        category_test: data.category_test,
                    },
                    headers: {
                        Authorization: `Bearer ${localStorage.token}`
                    }
                }).then(function (res) {
                    if (res.data.success) {
                        this.setting = res.data.data
                    }
                }.bind(this));
            }
        },
        async onSubmit() {
            let data = this.setting
            await axios.post(`${process.env.VUE_APP_URL_API}/admin/setting/setting-toefl/create-or-update`, data, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.token}`
                },
            }).then(res => {
                if(res.data.success) {
                    this.$swal({
                        toast: true,
                        title: 'TOEFL!',
                        text: res.data.message,
                        icon: 'success',
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })
                } else {
                    this.$swal({
                        toast: true,
                        title: 'TOEFL!',
                        text: 'Failed!',
                        icon: 'success',
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })
                }
                // this.getSetting();
                
            }).catch(err => {
                if (err?.response?.status == 422){
                    this.$swal({
                        toast: true,
                        title: 'TOEFL!',
                        text: err.response.data.message[0],
                        icon: 'error',
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })
                } else {
                    this.$swal({
                        toast: true,
                        title: 'TOEFL!',
                        text: 'Failed!',
                        icon: 'error',
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })
                }
            }).finally(() => this.process = false)
        }
    }
}
</script>