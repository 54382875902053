<template>
    <div class="card d-block w-100 border-0 shadow-xss rounded-lg overflow-hidden p-4 mb-3">
        <div class="row">
            <div class="col-12 mb-3 text-center">
                <span class="font-md text-primary fw-700">Detail Data</span>
            </div>
            <div class="col-12">
                <table class="table table-striped">
                    <tr>
                        <td class="text-current fw-600">Full Name</td>
                        <td class="text-black fw-500">{{data.fullname}}</td>
                    </tr>
                    <tr>
                        <td class="text-current fw-600">ID Number</td>
                        <td class="text-black fw-500">{{ data.id_no }}</td>
                    </tr>
                    <tr>
                        <td class="text-current fw-600">Information Source</td>
                        <td class="text-black fw-500">{{ data.partner_id.title }}</td>
                    </tr>
                    <tr>
                        <td class="text-current fw-600">Test Program</td>
                        <td class="text-black fw-500">{{ data.category_test }}</td>
                    </tr>
                    <tr>
                        <td class="text-current fw-600">Test Date</td>
                        <td class="text-black fw-500">{{ formatDate(data.test_date) }}</td>
                    </tr>
                    <tr>
                        <td class="text-current fw-600">Country</td>
                        <td class="text-black fw-500">{{ data.country }}</td>
                    </tr>
                    <tr>
                        <td class="text-current fw-600">Native Language</td>
                        <td class="text-black fw-500">{{ data.native_language }}</td>
                    </tr>
                    <tr>
                        <td class="text-current fw-600">Birth of Date</td>
                        <td class="text-black fw-500">{{ formatDate(data.birth_date) }}</td>
                    </tr>
                    <tr>
                        <td class="text-current fw-600">Gender</td>
                        <td class="text-black fw-500">{{ data.sex }}</td>
                    </tr>
                    <tr>
                        <td class="text-current fw-600">Mobile Number</td>
                        <td class="text-black fw-500">{{ data.no_phone }}</td>
                    </tr>
                    <tr>
                        <td class="text-current fw-600">Sender Name</td>
                        <td class="text-black fw-500">{{ data.user_name }}</td>
                    </tr>
                    <tr>
                        <td class="text-current fw-600">Sender Email</td>
                        <td class="text-black fw-500">{{ data.user_email }}</td>
                    </tr>
                    <tr>
                        <td class="text-current fw-600">Sender Mobile Number</td>
                        <td class="text-black fw-500">{{ data.user_phone }}</td>
                    </tr>
                </table>
            </div>
        </div>
    </div>
</template>
<script>
import moment from 'moment';
export default {
    props: ['data'],
    methods : {
        formatDate(date) {
            return moment(date).format('MMMM DD, YYYY');
        }
    }
}
</script>