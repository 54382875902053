<template>
    <div class="card d-block w-100 border-0 shadow-xss rounded-lg overflow-hidden p-4 mb-3">
        <div class="row">
            <div class="col-12 mb-3 text-center">
                <span class="font-md text-primary fw-700">Confirm Registration</span>
            </div>
            <div class="col-12">
                <form @submit.prevent="onSubmit()" class="row">
                    <div class="form-group col-lg-6">
                        <label for="" class="text-current fw-600">Full Name</label>
                        <input type="text" v-model="data.fullname" class="form-control" disabled required>
                    </div>
                    <div class="form-group col-lg-6">
                        <label for="" class="text-current fw-600">ID Number</label>
                        <input type="number" v-model="data.id_no" class="form-control" disabled required>
                    </div>
                    <div class="form-group col-lg-6">
                        <label for="" class="text-current fw-600">Test Date</label>
                        <input type="date" v-model="data.test_date" class="form-control" disabled required>
                    </div>
                    <div class="form-group col-lg-6">
                        <label for="" class="text-current fw-600">Times Taken</label>
                        <input type="number" v-model="data.times_taken" min="1" class="form-control" disabled required>
                    </div>
                    <div class="col-12">
                        <hr>
                    </div>
                    <div class="form-group col-lg-12">
                        <label for="" class="text-current fw-600">Confirm Status</label>
                        <select v-model="status" class="form-control" required>
                            <option value="" selected disabled>-- Select your Confirmation --</option>
                            <option value="2">Confirm</option>
                            <option value="6">Cancel</option>
                        </select>
                    </div>
                    <div class="form-group col-12">
                        <div class="form-check">
                            <input type="checkbox" class="form-check-input mt-2" v-model="confirm" required>
                            <label class="form-check-label font-xsss fw-500 text-grey-700" for="exampleCheck1">Make sure your confirmation</label>
                        </div>
                    </div>
                    <div class="form-group col-lg-12 text-right mt-2" v-if="!process">
                        <input class="btn btn-current" type="submit" value="Submit" :disabled="!confirm"/>
                    </div>
                    <div class="form-group col-lg-12 text-right mt-2" v-else>
                        <button class="btn btn-current" disabled>Processing...</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios'
export default {
    props: ['data'],
    data() {
        return {
            confirm: false,
            process: false,
            status: ''
        }
    },
    methods: {
        async onSubmit() {
            this.process = true
            let data = {
                id: this.data.id,
                status: this.status
            }
            await axios.patch(`${process.env.VUE_APP_URL_API}/admin/setting/toefl/set-status`, data, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.token}`
                },
            }).then(res => {
                if(res.data.success) {
                    this.$swal({
                        toast: true,
                        title: 'TOEFL!',
                        text: res.data.message,
                        icon: 'success',
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })
                } else {
                    this.$swal({
                        toast: true,
                        title: 'TOEFL!',
                        text: 'Failed confirmation!',
                        icon: 'success',
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })
                }
            }).catch(err => {
                if (err?.response?.status == 422){
                    this.$swal({
                        toast: true,
                        title: 'TOEFL!',
                        text: err.response.data.message[0],
                        icon: 'error',
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })
                } else {
                    this.$swal({
                        toast: true,
                        title: 'TOEFL!',
                        text: 'Failed confirmation!',
                        icon: 'error',
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })
                }
            }).finally(() => this.process = false)
        }
    }
}
</script>